import { useTheme } from '@mui/material/styles';
import {
  PATH_ABOUT,
  PATH_AFFILIATES,
  PATH_BLOG,
  PATH_CONTACT,
  PATH_FAQ,
  PATH_PRIVACY,
  PATH_TERMS,
  TIKTOK_URL,
  TIKTOK_HANDLE,
  INSTAGRAM_HANDLE,
  INSTAGRAM_URL,
  FB_URL,
  FB_HANDLE,
  PINTEREST_HANDLE,
  PINTEREST_URL,

  // PATH_HOLIDAYS,
  // PATH_MOTHERS_DAY,
  PATH_GRADUATION,
  PATH_FATHERS_DAY
} from 'constants';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from 'next/link';
import makeClasses from 'hooks/useClasses';
import MuiLink from '@mui/material/Link';
import React from 'react';
import Stack from '@mui/material/Stack';
import styles from 'styles/jss/components/footerStyle';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

// import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import PinterestIcon from '@mui/icons-material/Pinterest';
import TikTokIcon from 'components/Icons/TikTokIcon';

const social = [
  {
    url: INSTAGRAM_URL,
    alt: `Instagram ${INSTAGRAM_HANDLE}`,
    icon: <InstagramIcon />
  },
  {
    url: FB_URL,
    alt: `Facebook ${FB_HANDLE}`,
    icon: <FacebookIcon />
  },
  {
    url: PINTEREST_URL,
    alt: `Pinterest ${PINTEREST_HANDLE}`,
    icon: <PinterestIcon />
  },
  {
    url: TIKTOK_URL,
    alt: `Pinterest ${TIKTOK_HANDLE}`,
    icon: <TikTokIcon />
  }
];

const Footer = () => {
  const theme = useTheme();
  const classes = makeClasses(styles);
  const d = new Date();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <footer className={classes.footer}>
      <Grid
        container
        spacing={3}
        sx={{
          px: { xs: 4, md: 6 },
          py: 6
        }}
      >
        <Grid
          item
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          xs={12}
        >
          <Stack
            className={classes.footerLinks}
            direction="row"
            spacing={{ xs: 5, md: 5 }}
          >
            <Link href={PATH_PRIVACY} passHref>
              <MuiLink color="inherit" underline="hover" component="a">
                {isSmall ? 'Privacy' : 'Privacy Policy'}
              </MuiLink>
            </Link>
            <Link href={PATH_TERMS} passHref>
              <MuiLink color="inherit" underline="hover" component="a">
                Terms of Service
              </MuiLink>
            </Link>
            <Link href={PATH_CONTACT} passHref>
              <MuiLink color="inherit" underline="hover" component="a">
                Contact
              </MuiLink>
            </Link>
            <Link href={PATH_BLOG} passHref>
              <MuiLink color="inherit" underline="hover" component="a">
                Blog
              </MuiLink>
            </Link>
            <Link href={PATH_ABOUT} passHref>
              <MuiLink color="inherit" underline="hover" component="a">
                About
              </MuiLink>
            </Link>
            <Link href={PATH_FAQ} passHref>
              <MuiLink color="inherit" underline="hover" component="a">
                FAQ
              </MuiLink>
            </Link>
            <Link href={PATH_AFFILIATES} passHref>
              <MuiLink color="inherit" underline="hover" component="a">
                Affiliates
              </MuiLink>
            </Link>
            <Link href={PATH_GRADUATION} passHref>
              <MuiLink color="inherit" underline="hover" component="a">
                Graduations
              </MuiLink>
            </Link>
            <Link href={PATH_FATHERS_DAY} passHref>
              <MuiLink color="inherit" underline="hover" component="a">
                Father&apos;s Day
              </MuiLink>
            </Link>
          </Stack>
        </Grid>
        <Grid
          className={classes.socialLinks}
          item
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          xs={12}
        >
          <Stack
            direction="row"
            spacing={{ xs: 4, md: 5 }}
          >
            {social.map((s) => (
              <Link
                key={s.url}
                href={s.url}
                target="_blank"
                passHref
              >
                <IconButton
                  size="small"
                  color="white"
                  target="_blank"
                  component="a"
                  aria-label={s.alt}
                >
                  {s.icon}
                </IconButton>
              </Link>
            ))}
          </Stack>
        </Grid>
        <Grid
          item
          justifyContent="center"
          alignContent="center"
          alignItems="center"
          xs={12}
        >
          <Typography textAlign="center" className={classes.copyright}>
            &copy;
            {` ${d.getFullYear()} `}
            Scribble Creativity, Inc. All Rights Reserved.
          </Typography>
        </Grid>
      </Grid>
    </footer>
  );
};

export default Footer;
