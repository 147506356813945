const header = (theme) => ({
  appBar: {
    width: '100%',
    border: 'none'
  },

  logoBox: {
    display: 'flex',
    flexGrow: 1,
    alignContent: 'center',
    alignItems: 'center'
  },

  productMenu: {
    '.productHeader': {
      paddingLeft: 15,
      paddingTop: 10,
      fontWeight: 800
    },
    '.MuiButtonBase-root': {
      whiteSpace: 'normal',
      gap: 8,

      '.productTitle': {
        fontWeight: 'bold',
        width: 210
      },
      '.fera-productReviewsSummary-reviewCount': {
        display: 'none'
      }
    }
  },

  continueBox: {
    textAlign: 'center',
    width: 300,
    whiteSpace: 'normal',

    [theme.breakpoints.down('sm')]: {
      minWidth: '50vw'
    },

    '.MuiTypography-root': {
      whiteSpace: 'normal'
    }
  },

  continueSwiper: {
    '.swiper-pagination': {
      bottom: 0,

      '.swiper-pagination-bullet-active': {
        backgroundColor: theme.palette.primary.main
      }
    },

    '.continueSlide': {
      // Fixes centering
      boxSizing: 'border-box'
    },

    '.swiper-button-prev, .swiper-button-next': {
      top: 100,
      '--swiper-navigation-size': '20px',
      color: theme.palette.primary.main,
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    }
  }
});
export default header;
