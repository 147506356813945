import {
  PATH_BLOG,
  PATH_FAQ,
  PATH_HOME,
  PATH_PRODUCT_11X85,
  PATH_PRODUCT_85X85,
  PATH_PRODUCT_WRAPPING_PAPER,
  PATH_PRODUCT_GIFT_CARDS,
  // PATH_HOLIDAYS,
  // PATH_MOTHERS_DAY,
  PATH_GRADUATION,
  PATH_FATHERS_DAY
} from 'constants';
import AppBar from '@mui/material/AppBar';
// import Avatar from '@mui/material/Avatar';
// import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Image from 'components/Image';
import Link from 'next/link';
import ListItemIcon from '@mui/material/ListItemIcon';
// import LoginIcon from '@mui/icons-material/Login';
// import Logout from '@mui/icons-material/Logout';
import makeClasses from 'hooks/useClasses';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
// import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import styles from 'styles/jss/components/headerStyle';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';

// import FavoriteOutlinedIcon from '@mui/icons-material/FavoriteOutlined';
import SchoolIcon from '@mui/icons-material/School';
import GolfCourseIcon from '@mui/icons-material/GolfCourse';

const PRODUCTS = [
  {
    title: '11" x 8.5" Hardcover Kid\'s Art Photo Book',
    url: PATH_PRODUCT_11X85,
    thumb: '/img/products/11x8-5_marble_thumb.jpg'
  },
  {
    title: '8.5" x 8.5" Hardcover Kid\'s Art Photo Book',
    url: PATH_PRODUCT_85X85,
    thumb: '/img/products/8-5x8-5_marble_thumb.jpg'
  },
  {
    id: 4000,
    title: 'Kid\'s Art Wrapping Paper',
    url: PATH_PRODUCT_WRAPPING_PAPER,
    thumb: '/img/products/wrapping_paper_thumb.jpg',
    new: true
  }
];

const Header = () => {
  const classes = makeClasses(styles);
  const [anchorLeftMenu, setAnchorLeftMenu] = useState(null);

  const handleOpenLeftMenu = (event) => {
    setAnchorLeftMenu(event.currentTarget);
  };

  const handleCloseLeftMenu = () => {
    setAnchorLeftMenu(null);
  };

  return (
    <AppBar position="static" className={classes.appBar} id="appToolbar">
      <Container maxWidth="lg">
        <Toolbar disableGutters>
          <Box sx={{ flexGrow: 0 }} display="flex">
            <Tooltip title="Menu">
              <IconButton onClick={handleOpenLeftMenu} sx={{ p: 0 }}>
                <MenuIcon color="white" />
              </IconButton>
            </Tooltip>
            <Menu
              className={classes.productMenu}
              sx={{ mt: '45px' }}
              disableScrollLock
              anchorEl={anchorLeftMenu}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              open={Boolean(anchorLeftMenu)}
              onClose={handleCloseLeftMenu}
            >
              <Box>
                <Typography className="productHeader" color="primary">
                  Our Products
                </Typography>
              </Box>
              {PRODUCTS.map((product) => (
                <Link
                  href={`${process.env.NEXT_PUBLIC_APP_ROOT}${product.url}`}
                  passHref
                  key={product.title}
                >
                  <MenuItem
                    component="a"
                    onClick={handleCloseLeftMenu}
                    sx={{ width: 350 }}
                  >
                    <Box
                      width={105}
                      height={78}
                      position="relative"
                      display="flex"
                    >
                      <Image
                        src={product.thumb}
                        layout="fill"
                        objectFit="contain"
                        priority
                      />
                    </Box>
                    <Stack direction="column" flexGrow={1}>
                      <Typography sx={{ mb: 0 }} className="productTitle">
                        {product.title}
                      </Typography>
                      {product.new && (
                        <Chip
                          sx={{
                            display: 'flex',
                            width: 50
                          }}
                          color="primary"
                          label="New"
                          size="small"
                        />
                      )}
                    </Stack>
                  </MenuItem>
                </Link>
              ))}
              <Divider sx={{ my: 0.5 }} />
              <Link
                href={PATH_FATHERS_DAY}
                passHref
              >
                <MenuItem onClick={handleCloseLeftMenu}>
                  <ListItemIcon>
                    <GolfCourseIcon fontSize="small" />
                  </ListItemIcon>
                  Father&apos;s Day
                </MenuItem>
              </Link>
              <Link
                href={PATH_GRADUATION}
                passHref
              >
                <MenuItem onClick={handleCloseLeftMenu}>
                  <ListItemIcon>
                    <SchoolIcon fontSize="small" />
                  </ListItemIcon>
                  Graduations
                </MenuItem>
              </Link>
              <Link
                href={PATH_PRODUCT_GIFT_CARDS}
                passHref
              >
                <MenuItem onClick={handleCloseLeftMenu}>
                  <ListItemIcon>
                    <CardGiftcardIcon fontSize="small" />
                  </ListItemIcon>
                  Buy a Gift Card
                </MenuItem>
              </Link>
              <Link href={PATH_FAQ} passHref>
                <MenuItem onClick={handleCloseLeftMenu}>
                  <ListItemIcon>
                    <HelpCenterOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  FAQ
                </MenuItem>
              </Link>
              <Link href={PATH_BLOG} passHref>
                <MenuItem onClick={handleCloseLeftMenu}>
                  <ListItemIcon>
                    <AutoStoriesOutlinedIcon fontSize="small" />
                  </ListItemIcon>
                  Blog
                </MenuItem>
              </Link>
            </Menu>
          </Box>
          <Box
            className={classes.logoBox}
            sx={{
              justifyContent: 'center'
            }}
          >
            <a
              alt="Scribble home"
              href={PATH_HOME}
              style={{
                display: 'flex',
                position: 'relative',
                width: 121,
                height: 31
              }}
            >
              <Image
                src="/img/logo_nav@2x.png"
                width={121}
                height={31}
                alt="Scribble Logo"
                priority
              />
            </a>
          </Box>
          <Box sx={{ flexGrow: 0 }} />
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
